import Price from "./Price";
import { buildPhotoUrl, t } from "../helpers";
import React from "react";
import { useParams } from "react-router-dom";
import { formatPrice } from "../logic/helpers";
import CONSTANTS from "../constants";

function CartLine(props) {
  let { lang } = useParams();

  function showControls() {
    return (
      !props.orderline.variationsSelection &&
      !props.orderline.subscription_type &&
      !(
        props.orderline.type &&
        ["trial", "post_checkout", "pre_checkout"].includes(
          props.orderline.type
        )
      )
    );
  }

  return (
    <div
      className={`orderline-row ${
        props.centerControls ? "orderline-present" : ""
      }`}
    >
      <div className="cart-image inner-orderline-row">
        <img
          src={buildPhotoUrl("photos/" + props.orderline.image)}
          alt={props.orderline.name}
        />
      </div>
      {props.simpleLine ? (
        <div className="orderline-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="orderline-name">
              <p>{props.orderline.name}</p>
            </div>
            <div className="number inner-orderline-row">
              <p>x {props.orderline.number}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="orderline-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="orderline-name">
              <p>
                {props.orderline.name}
                {props.orderline.subscription_type &&
                  props.orderline.subscription_type.includes("GOURMET") && (
                    <span
                      style={{
                        marginBottom: 14,
                        display: "block",
                        fontFamily: "Brown-Light",
                      }}
                    >
                      {t("clubPrice").toUpperCase()}
                    </span>
                  )}
              </p>
              {props.orderline.type ===
                CONSTANTS.ORDERLINE_TYPES.PRE_CHECKOUT && (
                <span style={{ marginBottom: 14, display: "block" }}>
                  {t("special_offer")}
                </span>
              )}{" "}
            </div>

            <div className="price inner-orderline-row">
              <p>
                <Price
                  prices={props.orderline}
                  className="product-loop"
                  showGourmetDiscount={
                    props.orderline.subscription_type &&
                    props.orderline.subscription_type.includes("GOURMET")
                  }
                />
              </p>
            </div>
          </div>
          <div className="">
            {props.orderline.subscription_type && (
              <div className="subscription-details-cart">
                {props.orderline.subscription_interval && (
                  <span>
                    {t("subscriptionOption", {
                      value: props.orderline.subscription_interval / 7,
                    })}
                  </span>
                )}
              </div>
            )}
            {props.orderline.type && props.orderline.type === "trial" && (
              <div className="subscription-details-cart">
                {props.orderline.discounted ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("includesBalance", {
                        price: formatPrice(
                          props.orderline.discounted,
                          props.orderline.symbol,
                          props.orderline.format
                        ),
                      }),
                    }}
                  ></span>
                ) : (
                  props.orderline.price && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("includesBalance", {
                          price: formatPrice(
                            props.orderline.price,
                            props.orderline.symbol,
                            props.orderline.format
                          ),
                        }),
                      }}
                    ></span>
                  )
                )}
              </div>
            )}
            {props.orderline.variationsSelection && (
              <ul className="variations-list-cart">
                {props.orderline.variationsSelection.map((variation, index) => (
                  <li key={index}>
                    <figure>
                      <img
                        src={`https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&image=https://global.cocunat.com/photos/${variation.image}`}
                      />
                    </figure>
                    {variation.name}
                  </li>
                ))}
              </ul>
            )}
            <div className={showControls() ? "controls" : "controls num-only"}>
              {props.plusOneProduct && showControls() && (
                <button
                  onClick={() =>
                    props.plusOneProduct(
                      props.orderline.id,
                      props.orderline.subscription_type,
                      props.orderline.type
                    )
                  }
                >
                  +
                </button>
              )}
              <span>{props.orderline.number}</span>
              {props.minusOneProduct && showControls() && (
                <button
                  onClick={() =>
                    props.minusOneProduct(
                      props.orderline.id,
                      props.orderline.subscription_type,
                      props.orderline.type
                    )
                  }
                >
                  -
                </button>
              )}

              {props.deleteProduct && (
                <span
                  className="delete-orderline"
                  onClick={() =>
                    props.deleteProduct(
                      props.orderline.id,
                      props.orderline.subscription_type,
                      props.orderline.type
                    )
                  }
                >
                  {t("delete_item", lang)}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartLine;
