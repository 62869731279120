import { createContext, useState, useEffect } from "react";
import { Subscription } from "@cocunat/cocunat-core-js";
import {
  getAccountSubscriptions,
  getAmbassador,
  saveAmbassador,
  saveSubscription,
} from "../logic/account";

const AccountContext = createContext(null);

function AccountContextProvider({ children }) {
  const [subscriptions, setSubscriptions] = useState({});
  const [ambassador, setAmbassador] = useState(null);
  /**
   * @description Adds the subscription to the subscriptions object using the subscription uuid as key
   */
  function addSubscription(uuid, subscription) {
    setSubscriptions({ ...subscriptions, [uuid]: subscription });
  }

  /**
   * @description Updates the subscription in the subscriptions object using the subscription uuid as key
   */
  function updateSubscription(uuid, subscription, callback) {
    let updatedSubscription = { ...subscriptions[uuid], ...subscription };
    if (subscription.packs_variations === null) {
      delete updatedSubscription.packs_variations;
    }
    setSubscriptions({ ...subscriptions, [uuid]: updatedSubscription });
  }

  function addProduct(uuid, productData) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).addProduct(
      productData
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function plusOneProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).plusOne(
      productId
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function minusOneProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).minusOne(
      productId
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function removeProduct(uuid, productId) {
    console.log("removeProduct", uuid, productId);
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({
      ...subscription,
    }).removeProduct(productId);
    updateSubscription(subscription.uuid, subscriptionObject);
  }
  function hasProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return (
      new Subscription({ ...subscription }).findProductIndex(productId) > -1
    );
  }

  function getUpsellOrderline(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    const sub = new Subscription({ ...subscription });
    const index = sub.findProductIndex(productId);

    if (index === -1) return null;
    return sub.subscription.upsell_orderlines[index];
  }

  function isNextProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return new Subscription({ ...subscription }).isNextProduct(productId);
  }

  function getSubscriptionType(subscriptionType) {
    let subscriptions = Object.values(subscriptions);
    for (let subscription of subscriptions) {
      if (subscription.subscription_type === subscriptionType) {
        return subscription;
      }
    }
    return null;
  }

  function getSubscriptionTotal(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return null;
    return new Subscription({ ...subscription }).total();
  }

  async function fetchSubs() {
    try {
      const _subscriptions = await getAccountSubscriptions();
      let result = {};
      for (let subscription of _subscriptions.data) {
        result[subscription.uuid] = subscription;
      }
      setSubscriptions(result);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchAmbassador() {
    try {
      const response = await getAmbassador();
      console.log("fetchAmbassador", response.data);
      setAmbassador(response.data?.[0] || response.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchSubs();
    fetchAmbassador();
  }, []);

  async function patchSubscription(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let response = await saveSubscription(subscription);
    if (response.status === 200) {
      updateSubscription(uuid, { success: true });
    } else {
      updateSubscription(uuid, { success: false });
    }
  }

  async function patchAmbassador(ambassadorData) {
    if (!ambassadorData) return;
    try {
      const response = await saveAmbassador(ambassadorData);
      if (response.status === 200) {
        setAmbassador(response.data);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    return false;
  }

  return (
    <AccountContext.Provider
      value={{
        updateSubscription,
        hasProduct,
        subscriptions,
        getSubscriptionType,
        addSubscription,
        addProduct,
        removeProduct,
        patchSubscription,
        isNextProduct,
        getSubscriptionTotal,
        getUpsellOrderline,
        plusOneProduct,
        minusOneProduct,
        ambassador,
        patchAmbassador,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContextProvider, AccountContext };
