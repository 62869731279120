import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { overrideBodyClass, t } from "../helpers";
import ProductLoop from "../components/product/ProductLoop";
import ProductSkeleton from "../components/product/ProductSkeleton";

import {
  getProductsByCategory,
  getProductSkeletons,
  searchProducts,
} from "../logic/products";
import Header from "../components/Header";
import { CartContext } from "../context/cart";
import Footer from "../components/Footer";
import { visitCategory } from "../logic/analytics";

function Search() {
  let { lang, country, categoryId } = useParams();
  let location = useLocation();
  const queryUrl = new URLSearchParams(location.search);
  const categoryQuery = queryUrl.get("category");
  const searchByQuery = queryUrl.get("search_by");

  const {
    cart,
    categoriesCached,
    updateProductsCached,
    updateCategoriesCached,
    promo,
  } = useContext(CartContext);
  let [products, setProducts] = useState([]);
  let [searchRealTime, setSearchRealTime] = useState("");
  let [search, setSearch] = useState("");

  const CATEGORY_NAMES = React.useMemo(() => ({
    65: t("face"),
    66: t("body"),
    67: t("hair"),
    80: "C.NOMAKEUP",
    90: t("packs"),
    91: t("sample"),
  }));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (categoryId) {
      const prevTitle = document.title;
      document.title = "Cocunat - " + CATEGORY_NAMES[categoryId];
      visitCategory(document.title);
      return () => {
        document.title = prevTitle;
      };
    }
  }, [categoryId]);

  function sortedByClub(_products) {
    try {
      return _products.sort((a, b) => {
        try {
          if (
            a.prices &&
            a.prices.show_gourmet &&
            b.prices &&
            b.prices.show_gourmet
          ) {
            if (a.order > b.order) return -1;
            if (a.order < b.order) return 1;
          }
          if (a.prices && a.prices.show_gourmet) return -1;
          if (b.prices && b.prices.show_gourmet) return 1;
          if (a.order > b.order) return -1;
          if (a.order < b.order) return 1;
        } catch {}
        return 0;
      });
    } catch {
      return _products;
    }
  }

  function getSortedProducts() {
    if (searchByQuery === "club") {
      return sortedByClub(categoriesCached[categoryId]);
    }
    return categoriesCached[categoryId].sort((a, b) => {
      try {
        if (a.order > b.order) return -1;
        if (a.order < b.order) return 1;
      } catch {}
      return 0;
    });
  }

  useEffect(() => {
    let _promo = promo ? promo.code : "";
    console.log("promo", _promo);
    const fetchData = async () => {
      setProducts(getProductSkeletons(8));
      let products = [];
      if (!categoryId) {
        if (categoryQuery) {
          categoryId = categoryQuery;
        } else {
          categoryId = "default";
        }
      }
      try {
        products = await getProductsByCategory(
          categoryId,
          lang,
          country,
          _promo
        );
        let productsData = products.data;
        if (searchByQuery === "club") {
          productsData = sortedByClub(productsData);
        }
        setProducts(productsData);
        updateProductsCached(productsData);
        /*updateCategoriesCached(categoryId, products.data);*/
      } catch (err) {
        setProducts([]);
        console.log(err);
      }
    };
    if (categoriesCached.hasOwnProperty(categoryId)) {
      setProducts(getSortedProducts());
    } else {
      fetchData();
    }
    setTimeout(() => {
      scrollToTop();
    }, 100);
  }, [categoryId, promo]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async function fetchDataSearch(term) {
    if (term) {
      setSearch(term);
      setProducts(getProductSkeletons(8));
      let products = await searchProducts(term, lang);
      if (products.data.error) {
        console.log("ERROR");
      }
      setProducts(products.data);
      updateProductsCached(products.data);
    }
  }

  function onChangeSearch(e) {
    setSearchRealTime(e.target.value);
  }

  return (
    <>
      <Header goBack={"/"} />
      <section
        id="hero"
        className="fallback"
        style={{ display: "none" }}
      ></section>

      <div className="product-list container search">
        {CATEGORY_NAMES[categoryId] && (
          <h1
            style={{
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "40px",
            }}
          >
            {CATEGORY_NAMES[categoryId]}
          </h1>
        )}
        {search !== "" && (
          <div style={{ textAlign: "center", marginBottom: 50 }}>
            <h1 style={{ marginBottom: 10 }}>Resultados de tu búsqueda</h1>
            <span>{search}</span>
          </div>
        )}
        {products.map((product, i) => {
          if (!product.translation || !product.prices) {
            return <div key={i}></div>;
          }
          if (product.id === "skeleton") {
            return <ProductSkeleton key={i} />;
          } else {
            return <ProductLoop product={product} key={i} />;
          }
        })}
      </div>
      <Footer />
    </>
  );
}

export default Search;
