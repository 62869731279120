import { buildPhotoUrl } from "../helpers";
import React, { useContext, useEffect } from "react";
import { CartContext } from "../context/cart";

function PromoSticker({
  discount_applied,
  rendered_from,
  showGourmet = null,
  clubDiscount = null,
  lang = null,
  className = "promo-tag",
}) {
  const { promo } = useContext(CartContext);

  return (
    <>
      {showGourmet &&
        clubDiscount &&
        (!discount_applied || clubDiscount > discount_applied) && (
          <div className={`club-tag`}>-{clubDiscount}%</div>
        )}
      {promo &&
        promo.stickers &&
        ((discount_applied > 0 && (!clubDiscount || !showGourmet)) ||
          (clubDiscount && discount_applied > clubDiscount)) && (
          <div className={`promo-tag`}>-{discount_applied}%</div>
        )}
    </>
  );
}

export default PromoSticker;
