import axios from "axios";
import constants from "../constants";
import {
  getHeaders,
  getJWT,
  validateEmail,
  validateNumericOnly,
} from "../helpers";
import jwt_decode from "jwt-decode";

/**
 * We have to ask the Document ID for people in Canary Islands postalcode
 * 35* and 38*, and for people in the UK.
 *
 * @param {*} address
 * @returns
 */
export const askForDocumentId = (address) => {
  if (!address.country) {
    return false;
  }
  return (
    address.country.toUpperCase() === "GB" ||
    (address.country.toUpperCase() === "ES" &&
      ["35", "38"].includes(address.zip_code.substring(0, 2)))
  );
};

/**
 * We have to ask the State for people in US
 *
 * @param {*} address
 * @returns
 */
export const askForState = (address) => {
  if (!address.country) {
    return false;
  }
  return constants.STATES.hasOwnProperty(address.country.toUpperCase());
};

/**
 * Checks if an address is valid.
 * @param {*} address
 * @returns
 */
export const isValidAddress = (address) => {
  let askForDocument = askForDocumentId(address);
  let isStateMandatory = askForState(address);
  try {
    if (
      (askForDocument && !address.document_id) ||
      (isStateMandatory && !address.state) ||
      address.deleted
    ) {
      return false;
    }
    if (
      address.first_name.length > 1 &&
      address.last_name.length > 1 &&
      address.address.length > 1 &&
      address.city.length > 1 &&
      address.zip_code.length > 1 &&
      address.country.length > 1 &&
      address.phone.length > 1 &&
      validateNumericOnly(address.phone) &&
      validateEmail(address.email)
    ) {
      return true;
    }
  } catch (err) {}

  return false;
};

/**
 * Returns the user stored inside the localStorage JWT token.
 * @returns
 */
export function userLoggedIn() {
  const jwt = getJWT();
  if (jwt) {
    const decoded = jwt_decode(jwt);
    return decoded;
  }
  return null;
}

export function getUser(id) {
  return axios.get(`${constants.API_BASE_URL}users/${id}`, getHeaders());
}

export function changePassword(payload) {
  return axios.post(`${constants.API_BASE_URL}users/password/change/`, payload);
}

export function loginUser(payload) {
  return axios.post(`${constants.API_BASE_URL}users/login/`, payload);
}

export function emailExists(email) {
  return axios.post(constants.API_BASE_URL + "users/exists/", { email: email });
}

export function getAccountOrders() {
  return axios.get(
    constants.API_BASE_URL + "orders/?page_size=50",
    getHeaders()
  );
}

export function deleteAddress(addressId) {
  return axios.patch(
    constants.API_BASE_URL + "addresses/" + addressId + "/",
    { deleted: true },
    getHeaders()
  );
}

export function getAddress(addressId) {
  return axios.get(
    constants.API_BASE_URL + "addresses/" + addressId + "/",
    getHeaders()
  );
}

export function updateAddress(addressId, payload) {
  return axios.patch(
    constants.API_BASE_URL + "addresses/" + addressId + "/",
    payload,
    getHeaders()
  );
}

export function createAddress(payload) {
  return axios.post(
    constants.API_BASE_URL + "addresses/",
    payload,
    getHeaders()
  );
}

export async function getAccountBalances() {
  let email = await getAccountEmail();
  if (email) {
    return axios.get(
      constants.USERS_API_URL + "balances/" + email + "/all",
      getHeaders()
    );
  }
  return [];
}

export async function getAccountPaymentMethods() {
  let email = await getAccountEmail();
  if (email) {
    return axios.get(
      constants.USERS_API_URL + "payment_methods/" + email,
      getHeaders()
    );
  }
  return [];
}

export async function getClientSecret() {
  let email = await getAccountEmail();
  if (email) {
    return axios.get(
      constants.USERS_API_URL + "payment_methods/" + email + "/secret",
      getHeaders()
    );
  }
  return "";
}

export async function setDefaultPaymentMethod(paymentMethodId) {
  let email = await getAccountEmail();
  if (email) {
    let payload = {
      email: email,
      card_id: paymentMethodId,
    };
    return axios.patch(
      constants.USERS_API_URL + "payment_methods/default",
      payload,
      getHeaders()
    );
  }
  return false;
}

export async function deletePaymentMethod(paymentMethodId) {
  let email = await getAccountEmail();
  if (email) {
    let payload = {
      email: email,
      card_id: paymentMethodId,
    };
    return axios.delete(constants.USERS_API_URL + "payment_methods/", {
      data: payload,
      headers: {
        Authorization: getJWT(),
        "Content-Type": "application/json",
      },
    });
  }
  return false;
}

export async function updatePaymentMethod(paymentMethodId, expMonth, expYear) {
  let email = await getAccountEmail();
  if (email) {
    let payload = {
      email: email,
      card_id: paymentMethodId,
      exp_month: expMonth,
      exp_year: expYear,
    };
    return axios.patch(
      constants.USERS_API_URL + "payment_methods/",
      payload,
      getHeaders()
    );
  }
  return false;
}

export async function getAccountSubscriptions() {
  let email = await getAccountEmail();
  if (email) {
    return axios.get(
      constants.SUBSCRIPTION_API_URL + "user_subscriptions/" + email + "/",
      getHeaders()
    );
  }
  return [];
}

export async function getAmbassador() {
  return axios.get(constants.API_BASE_URL + "ambassadors/", getHeaders());
}

export async function saveOldSubscriptionSingle(subscriptionId, payload) {
  console.log(payload);
  return axios.patch(
    `${constants.API_BASE_URL}subscriptions/${subscriptionId}`,
    payload,
    getHeaders()
  );
}

export async function getAccountSubscriptionSingle(type, uuid) {
  let email = await getAccountEmail();
  if (email) {
    return axios.get(
      constants.SUBSCRIPTION_API_URL +
        "user_subscriptions/" +
        email +
        "/" +
        type +
        "/" +
        uuid +
        "/",
      getHeaders()
    );
  }
  return null;
}

export async function getAccountEmail() {
  let user = await getLoggedUser();
  if (user) {
    return user.data["email"];
  }
  return null;
}

export function saveSubscription(subscription) {
  delete subscription.sk;
  return axios.patch(
    constants.SUBSCRIPTION_API_URL + "user_subscriptions/",
    subscription,
    getHeaders()
  );
}

export function saveAmbassador(ambassador) {
  console.log(ambassador);
  return axios.patch(
    constants.API_BASE_URL + "ambassadors/",
    ambassador,
    getHeaders()
  );
}

export function getLoggedUser() {
  let user = jwt_decode(getJWT());
  return axios.get(
    constants.API_BASE_URL + "users/" + user["id"] + "/",
    getHeaders()
  );
}

export function createUser(payload) {
  return axios.post(constants.API_BASE_URL + "users/signup/", payload);
}

export function forgotPassword(email) {
  return axios.post(constants.API_BASE_URL + "users/password/remember/", {
    email: email,
  });
}

export function updatePassword(password, jwt) {
  return axios.post(constants.API_BASE_URL + "users/password/change/", {
    new_password: password,
    token: jwt,
  });
}

export function getEmailFromLocalStorage() {
  return localStorage.getItem("email");
}

export async function hasActiveGourmetSubscription(email) {
  const response = await axios.get(
    `${constants.SUBSCRIPTION_API_URL}user_subscriptions/${email}/?status__eq=active&subscription_type__begins_with=GOURMET`
  );
  return response.data.length > 0;
}
