import Checkbox from "./Checkbox";
import { t } from "../helpers";
import React, { useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";

const AcceptanceCheckboxes = ({
  errorTerms,
  setErrorTerms,
  errorPrivacy,
  setErrorPrivacy,
  termsSelected,
  setTermsSelected,
  privacySelected,
  setPrivacySelected,
  setError,
}) => {
  const [openModalPrivacy, setOpenModalPrivacy] = useState(false);
  const [openModalTerms, setOpenModalTerms] = useState(false);

  function checkAcceptance(_termsSelected, _privacySelected) {
    if (!_termsSelected) {
      setErrorTerms(true);
    }
    if (!_privacySelected) {
      setErrorPrivacy(true);
    }
    if (_termsSelected && _privacySelected) {
      setErrorTerms(false);
      setErrorPrivacy(false);
      setError("");
    } else {
      if (!_privacySelected) {
        setErrorPrivacy(true);
      }
      if (!_termsSelected) {
        setErrorTerms(true);
      }
      setError(t("error_privacy"));
    }
  }
  return (
    <>
      <PrivacyPolicy
        isOpen={openModalPrivacy}
        setIsOpen={setOpenModalPrivacy}
      />
      <Terms isOpen={openModalTerms} setIsOpen={setOpenModalTerms} />
      <div className="acceptance-checkbox">
        <Checkbox
          defaultValue={privacySelected}
          error={errorPrivacy && !privacySelected}
          onChange={(val) => {
            if (errorPrivacy) {
              checkAcceptance(val, termsSelected);
            }
            setPrivacySelected(val);
          }}
          label={t("accept_privacy_conditions.label")}
          actionLabel={t("accept_privacy_conditions.action_label")}
          onPressLabel={() => setOpenModalPrivacy(true)}
        />
        <Checkbox
          defaultValue={termsSelected}
          error={errorTerms && !termsSelected}
          onChange={(val) => {
            if (errorTerms) {
              checkAcceptance(val, privacySelected);
            }
            setTermsSelected(val);
          }}
          label={t("accept_terms_conditions.label")}
          actionLabel={t("accept_terms_conditions.action_label")}
          onPressLabel={() => setOpenModalTerms(true)}
        />
      </div>
    </>
  );
};

export default AcceptanceCheckboxes;
