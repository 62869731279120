import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { CartContext } from "../context/cart";
import CartLine from "../components/CartLine";
import Header from "../components/Header";
import Button from "../components/Button";
import CartTotals from "../components/CartTotals";

import GoBackLink from "../components/GoBackLink";
import PromoCodeInput from "../components/PromoCodeInput";
import PromoCartBanner from "../components/PromoCartBanner";
import { t } from "../helpers";
import ProgressBar from "../components/ProgressBar";
import Promises from "../components/Promises";
import EmptyCart from "../components/EmptyCart";
import { isCartTotalGreaterThanMinimumOrderAmount } from "@cocunat/cocunat-core-js";
import AmazonPayGateway from "../components/payment/AmazonPayGateway";
import CONSTANTS from "../constants";
import PaypalGateway from "../components/payment/PaypalGateway";
import CheckoutText from "../components/club/CheckoutText";

function Cart() {
  const {
    cart,
    region,
    plusOneProduct,
    minusOneProduct,
    deleteProduct,
    applyCartPromo,
    loading,
    promo,
    hasSubscription,
    hasTrial,
    markCartAsPaid,
    gourmetInCart,
    hasProduct,
  } = useContext(CartContext);

  let [promoCode, setPromoCode] = useState(cart ? cart.promo_code : "");
  let { lang, country } = useParams();
  const [allowAmazonPay, setAllowAmazonPay] = useState(false);
  const [allowPaypal, setAllowPaypal] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [minOrderAmount, setMinOrderAmount] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  function getMinOrderPromo() {
    if (promo && promo.lines) {
      for (let line of promo.lines) {
        if (
          line.type === "min_order" &&
          (!minOrderAmount || line.quantity < minOrderAmount)
        ) {
          return line.quantity;
        }
      }
    }
    return null;
  }

  function updateMinOrder() {
    // let newMinOrder = region?.min_order;
    // const minOrder = getMinOrderPromo();
    // const isDiscovery = hasProduct(CONSTANTS.DISCOVERY_BOX);
    // if (minOrder || minOrder === 0) {
    //   newMinOrder = minOrder;
    // }
    // if (isDiscovery) {
    //   newMinOrder = 0;
    // }
    setMinOrderAmount(1);
  }

  useEffect(() => {
    if (promo) {
      setPromoCode(promo.code);
      updateMinOrder();
    }
  }, [promo]);

  useEffect(() => {
    if (region) {
      updateMinOrder();
    }
  }, [region]);

  useEffect(() => {
    if (cart) {
      let needsPassword = hasSubscription() || hasTrial();
      setAllowAmazonPay(false);
      setAllowPaypal(
        !needsPassword &&
          CONSTANTS.PAYPAL_ACCEPTED_CURRENCIES.includes(cart.currency)
      );
    } else {
      setAllowAmazonPay(false);
      setAllowPaypal(false);
    }
  }, [cart]);

  function applyPromo(promoCode) {
    try {
      promoCode = promoCode.replace(/ /g, "").toUpperCase();
    } catch (err) {
      console.log(err);
    }
    applyCartPromo(promoCode);
  }

  function onChangePromoCode(e) {
    setPromoCode(e.target.value);
  }

  async function handlePaymentSuccess(newCart = null) {
    markCartAsPaid(newCart);
    setRedirectTo(`/${lang}-${country}/checkout/thanks/`);
  }

  async function handlePaymentError() {
    console.log("Payment error");
  }

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  if (loading) {
    return <span>Loading</span>;
  }

  return (
    <>
      <Header payLink={`/${lang}-${country}/checkout/address/`} />

      <div className="container-small">
        <GoBackLink to={`/${lang}-${country}/`} title={t("keep_shopping")} />
      </div>

      {cart && cart.orderlines.length > 0 ? (
        <>
          <div className="cart-title-header">
            <p>{t("your_cart")}</p>
          </div>

          <div
            id="secondary-go-checkout-button"
            className="container-small"
            style={{ marginTop: 15 }}
          >
            {/* MEJORAR ESTO CUANDO HAYA MAS CALMA */}
            {region &&
              isCartTotalGreaterThanMinimumOrderAmount(
                cart,
                minOrderAmount
              ) && (
                <Link to={`/${lang}-${country}/checkout/address/`}>
                  <Button size="big" width="wide">
                    <span>{t("go_checkout")}</span>
                  </Button>
                </Link>
              )}
          </div>

          {cart.totals && cart.totals.percentage_for_free_shipping > 0 && (
            <div
              className="free-shipping-progressbar container-small"
              style={{ marginTop: 15 }}
            >
              <ProgressBar
                completed={cart.totals.percentage_for_free_shipping}
                amount={cart.totals.amount_for_free_shipping}
                currency={cart.totals.currency}
                type="free_shipping"
              />
            </div>
          )}
          {cart.totals &&
            cart.totals.rewards &&
            cart.totals.rewards.next_reward_text &&
            !cart.totals.percentage_for_free_shipping && (
              <div
                className="free-shipping-progressbar container-small"
                style={{ marginTop: 15 }}
              >
                <ProgressBar
                  completed={cart.totals.rewards.percentage_for_next_reward}
                  amount={cart.totals.rewards.amount_for_next_reward}
                  currency={cart.totals.currency}
                  type="next_reward"
                />
              </div>
            )}

          <div className="orderlines-list container-small">
            {cart.orderlines.map((orderline, i) => {
              return (
                <CartLine
                  key={i}
                  orderline={orderline}
                  plusOneProduct={plusOneProduct}
                  minusOneProduct={minusOneProduct}
                  deleteProduct={deleteProduct}
                />
              );
            })}
          </div>
          <div className="presents-list container-small">
            {cart.presents.map((present, i) => {
              return (
                <CartLine key={i} orderline={present} centerControls={true} />
              );
            })}
          </div>
          <div className="orderlines-list container-small">
            {gourmetInCart && region && <CheckoutText region={region} />}
          </div>

          <div className="container-small">
            {cart.totals &&
              cart.totals.rewards &&
              cart.totals.rewards.current_reward_text && (
                <PromoCartBanner
                  text={cart.totals.rewards.current_reward_text}
                />
              )}

            {cart.totals &&
              cart.totals.rewards &&
              cart.totals.rewards.next_reward_text && (
                <PromoCartBanner text={cart.totals.rewards.next_reward_text} />
              )}

            <CartTotals />
            {cart.promo_code && !promo && (
              <div className="alert alert-danger">
                ⚠ {t("error_discount_not_existing")}
              </div>
            )}
            {promo?.type !== "hidden" && (
              <PromoCodeInput
                onSubmit={() => applyPromo(promoCode)}
                value={promoCode}
                onChangeInput={onChangePromoCode}
                lang={lang}
              />
            )}

            {region &&
            cart.totals &&
            minOrderAmount >=
              cart.totals.subtotal -
                cart.totals.discount +
                cart.totals.shipping ? (
              <div className="alert alert-warning">
                {region.extra.min_order_text}
              </div>
            ) : (
              <div id="cart-go-checkout" style={{ backgroundColor: "white" }}>
                {allowAmazonPay && (
                  <AmazonPayGateway
                    handlePaymentSuccess={() => {}}
                    handlePaymentError={() => {}}
                    showPayButton={true}
                    isExpress={true}
                  />
                )}
                {allowPaypal && (
                  <PaypalGateway
                    handlePaymentSuccess={handlePaymentSuccess}
                    handlePaymentError={handlePaymentError}
                    showPayButton={true}
                    isExpress={true}
                  />
                )}

                <Link to={`/${lang}-${country}/checkout/address/`}>
                  <Button size="big" width="wide">
                    <span>{t("go_checkout")}</span>
                  </Button>
                </Link>
              </div>
            )}

            <section
              id="cart-perks"
              style={{
                textAlign: "center",
                borderTop: "1px solid #efefef",
                borderBottom: "1px solid #efefef",
                margin: "30px 0px",
                padding: "0px 0px 30px 0px",
              }}
            >
              {region && !cart.max_restock_date ? (
                <p>
                  {t("shipping_title")}:{" "}
                  {t("shipping_days", {
                    delivery: cart?.carrier_info?.delivery
                      ? cart.carrier_info.delivery
                      : region?.carriers?.[0]?.delivery,
                  })}
                </p>
              ) : (
                region &&
                cart.max_restock_date && (
                  <p>
                    {t("presale_shipping")}: {cart.max_restock_date}
                  </p>
                )
              )}
              <Promises style={{ justifyContent: "center" }} />
              <div
                style={{
                  borderBottom: "1px solid #efefef",
                  lineHeight: "0.1em",
                  margin: "25px 0px 35px",
                  fontWeight: 100,
                  fontSize: 16,
                }}
              >
                <span
                  style={{
                    background: "white",
                    padding: "0px 15px",
                    fontFamily: "Brown-Bold",
                  }}
                >
                  {t("safe_payment")}
                </span>
              </div>
              <img src="https://resizing.cocunat.com/r/?quality=90&fit=cover&width=275&height=74&image=https://global.cocunat.com/photos/img-payment-logos-2022.png" />
            </section>
          </div>
          <p style={{ margin: "50px 0px 130px 0px", textAlign: "center" }}>
            C/ Folgueroles 17-25, Barcelona, CA, 08022. <br /> Spain
          </p>
        </>
      ) : (
        <EmptyCart />
      )}
    </>
  );
}

export default Cart;
