import React, { useContext, useState } from "react";
import { t, buildPhotoUrl } from "../../helpers";

const CheckoutText = React.memo(({ region }) => {
  if (!region) {
    return <div></div>;
  }

  return (
    <>
      {region && (
        <div className="orderline-row">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="cart-image inner-orderline-row">
              <img
                src={buildPhotoUrl("photos/club_1_new.png")}
                alt="club"
                style={{ height: 50, maxWidth: "100%" }}
              />
            </div>
            <div className="orderline-name">
              <p
                style={{
                  fontFamily: "Brown-Light",
                  textTransform: "none",
                  margin: 0,
                }}
              >
                <strong
                  dangerouslySetInnerHTML={{
                    __html: t("checkout_club_title"),
                  }}
                ></strong>
              </p>
              <p
                style={{
                  fontFamily: "Brown-Light",
                  textTransform: "none",
                  margin: 0,
                }}
                dangerouslySetInnerHTML={{
                  __html: t("checkout_club_subtitle"),
                }}
              ></p>
              <small
                style={{
                  fontFamily: "Brown-Light",
                  textTransform: "none",
                  margin: 0,
                }}
                dangerouslySetInnerHTML={{
                  __html: t("checkout_club_text", {
                    price: region.extra.club_price,
                  }),
                }}
              ></small>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default CheckoutText;
