import { t } from "../helpers";
import CountrySelector from "./CountrySelector";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import { CartContext } from "../context/cart";
import { getAllProductsGroupedByCategory } from "../logic/products";
import InputGroup from "../components/InputGroup";
import Button from "../components/Button";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "../components/Terms";
import AcceptanceCheckboxes from "../components/AcceptanceCheckboxes";

import { addLead } from "../logic/helpers";
import { CookieBanner } from "@palmabit/react-cookie-law";

const removeMarketingCookies = () => {
  window["rsci-disable-tracking"] = true;
  window["ga-disable-UA-42631937-5"] = true;
  // delete all cookies
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

const removeStadisticsCookies = () => {
  window["rsci-disable-tracking"] = true;
  window["ga-disable-UA-42631937-5"] = true;
  // delete all cookies
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

const Footer = React.memo(() => {
  let { lang, country } = useParams();
  const { cart, promo, hasLoadedCache, populateCategoriesAndProductsCaches } =
    useContext(CartContext);

  const stateRef = useRef({
    cart: cart,
    promo: promo,
  });
  const setStateRef = (data) => {
    stateRef.current = data;
  };

  let [email, setEmail] = useState("");
  let [emailLoading, setEmailLoading] = useState(false);
  const [openModalPrivacy, setOpenModalPrivacy] = useState(false);
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [termsSelected, setTermsSelected] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(false);

  function changeEmail(e) {
    setEmail(e.target.value);
  }

  async function saveLead() {
    if (!privacySelected || !termsSelected) {
      setErrorPrivacy(!privacySelected);
      setErrorTerms(!termsSelected);
      setError(t("error_privacy"));
      setLoading(false);
      return;
    }
    let payload = {
      language: lang,
      country: country,
      form_place: "nw_footer_web",
      email: email,
    };
    setEmailLoading(true);
    try {
      let response = await addLead(payload);
      if (response.status === 201) {
        console.log("lead");
        setEmailLoading(false);
        setEmail("");
        setTermsSelected(false);
        setPrivacySelected(false);
      }
    } catch (e) {
      console.log(e);
      setEmailLoading(false);
      setEmail("");
      setTermsSelected(false);
      setPrivacySelected(false);
    }
  }

  function checkAcceptance(_termsSelected, _privacySelected) {
    if (!_termsSelected) {
      setErrorTerms(true);
    }
    if (!_privacySelected) {
      setErrorPrivacy(true);
    }
    if (_termsSelected && _privacySelected) {
      setErrorTerms(false);
      setErrorPrivacy(false);
      if (error === t("error_privacy")) {
        setError(null);
      }
    } else {
      if (!_privacySelected) {
        setErrorPrivacy(true);
      }
      if (!_termsSelected) {
        setErrorTerms(true);
      }
      setError(t("error_privacy"));
    }
  }

  useEffect(() => {
    setStateRef({
      cart: cart,
      promo: promo,
    });
  }, [cart, promo]);

  const lazyLoad = async () => {
    if (!hasLoadedCache) {
      setTimeout(async () => {
        try {
          await populateCategoriesAndProductsCaches(
            lang,
            country,
            stateRef.current.cart.promo_code
          );
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    }
    window.removeEventListener("scroll", lazyLoad);
  };

  useEffect(() => {
    window.addEventListener("scroll", lazyLoad);
  }, []);

  return (
    <section id="footer">
      <CookieBanner
        message={t("cookie_message")}
        wholeDomain={true}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
        onDeclineMarketing={removeMarketingCookies}
        privacyPolicyLinkText={t("privacy_policy")}
        policyLink={`/${lang}-${country}/content/politica-de-privacidad`}
        acceptButtonText={t("accept")}
        managePreferencesButtonText={t("manage_my_cookies")}
        savePreferencesButtonText={t("save_and_accept")}
        necessaryOptionText={t("necessary")}
        preferencesOptionText={t("preferences")}
        statisticsOptionText={t("statistics")}
        marketingOptionText="Marketing"
        styles={{
          container: {
            maxWidth: "960px",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
            paddingBottom: "5px",
          },
          dialog: {
            bottom: "0px",
            top: "auto",
            position: "fixed",
            width: "100%",
            background: "#eee",
            zIndex: "999",
          },
        }}
      />
      <PrivacyPolicy
        isOpen={openModalPrivacy}
        setIsOpen={setOpenModalPrivacy}
      />

      <Terms isOpen={openModalTerms} setIsOpen={setOpenModalTerms} />

      <div className="pictures">
        <div className="container-wide">
          <div className="title">
            <p style={{ marginBottom: "0px" }}>{t("click_buy")}</p>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/boombastic-basica`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/myblueberrynightsblog-amp.jpg`}
                height="280"
                alt="@myblueberrynightsblog"
                loading="lazy"
                width="280"
              />
              <span>@myblueberrynightsblog</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/rizos-cocunat-en-3-pasos`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/glorirovi-amp.jpg`}
                height="280"
                alt="@glorirovi"
                loading="lazy"
                width="280"
              />
              <span>@glorirovi</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/search/80`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/nat-cebrian-amp.jpg`}
                height="280"
                alt="@nat.cebrian"
                loading="lazy"
                width="280"
              />
              <span>@nat.cebrian</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/the-absolute-crema-antiedad-rejuvenecedora-50ml`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/pau_eche-amp.jpg`}
                height="280"
                alt="@pau_eche"
                loading="lazy"
                width="280"
              />
              <span>@pau_eche</span>
            </a>
          </div>
          <div className="content-icons">
            <a
              href="https://www.instagram.com/cocunat/ "
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/icon-instagram-amp.png`}
                height="50"
                loading="lazy"
                alt="instagram"
                width="50"
                style={{ marginRight: "10px" }}
              />
            </a>
            <a
              href="https://www.facebook.com/Cocunat/"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/icon-facebook-amp.png`}
                height="50"
                alt="facebook"
                loading="lazy"
                width="50"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              />
            </a>
            <a
              href="https://www.tiktok.com/@cocunat?lang=es&is_copy_url=1&is_from_webapp=v1"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/tiktok-icon.png`}
                height="50"
                alt="facebook"
                loading="lazy"
                width="50"
                style={{ marginLeft: "10px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="leadForm">
        <div
          className="container-cocunat"
          style={{ maxWidth: "650px", margin: "auto" }}
        >
          <p>
            {t("nw_title")}
            {t("nw_text")}
          </p>
          <InputGroup
            id="email"
            name="email"
            title={t("email")}
            className="nw_email"
            value={email}
            onChange={changeEmail}
          />
          <Button
            className="btn-subscribe"
            size="small"
            onClick={saveLead}
            loading={emailLoading}
            style={{ backgroundColor: "black" }}
          >
            <span>{t("nw_button")}</span>
          </Button>
          {error && (
            <div className="alert alert-danger" style={{ fontSize: 14 }}>
              ⚠ {error}
            </div>
          )}

          <AcceptanceCheckboxes
            errorTerms={errorTerms}
            setErrorTerms={setErrorTerms}
            errorPrivacy={errorPrivacy}
            setErrorPrivacy={setErrorPrivacy}
            termsSelected={termsSelected}
            setTermsSelected={setTermsSelected}
            privacySelected={privacySelected}
            setPrivacySelected={setPrivacySelected}
            setError={setError}
          />
        </div>
      </div>
      <div
        className="container-wide footer-links"
        style={{ overflow: "hidden", padding: "30px 0px" }}
      >
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>{t("our_products")}</strong>
          </p>
          <ul className="list-footer">
            <li>
              <Link to={`/${lang}-${country}/search/65`}>{t("face")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/66`}>{t("body")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/67`}>{t("hair")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/90`}>{t("packs")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/66`}>C.NOMAKEUP</Link>
            </li>
          </ul>
        </div>
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>{t("security_and_privacy")}</strong>
          </p>
          <ul className="list-footer">
            <li>
              <Link to={`/${lang}-${country}/content/${t("terminos")}`}>
                {t("terms_of_service")}
              </Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/content/${t("devoluciones")}`}>
                {t("delivery_returns_policy")}
              </Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/content/${t("compra")}`}>
                {t("safe_purchase")}
              </Link>
            </li>
            <li>
              <a onClick={() => setOpenModalPrivacy(true)}>
                {t("privacy_policy")}
              </a>
            </li>
          </ul>
        </div>
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>Cocunat</strong>
          </p>
          <ul className="list-footer">
            <li>
              <a href="https://others.cocunat.com/contact">{t("contact")}</a>
            </li>
            <li>
              <a href={t("home-welcome-link")}>{t("about_us")}</a>
            </li>
            <li>
              <a href="https://cocunat.jobs.personio.de/">
                {t("work_with_us")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="input-group" style={{ width: "250px", margin: "auto" }}>
        <CountrySelector />
      </div>
      <p>&copy; 2022 Cocunat. All Rights Reserved</p>
    </section>
  );
});

export default Footer;
