import React from "react";
import { t } from "../../helpers";
import VideoPlayer from "./../VideoPlayer/VideoPlayer";

const ProductTutorial = React.memo(({ product, isMakeup = false }) => {
  function getTutorialVideo(productId) {
    let videos = {
      1: ["3a553367f25dab61a085ec95b72876ac"],
      2: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      3: ["6e80ad803118de2c0f8c17446722a7bf"],
      53234: ["6e80ad803118de2c0f8c17446722a7bf"],
      4: ["6c5900dfec409a648a1d0bccca4896be"],
      53235: ["6c5900dfec409a648a1d0bccca4896be"],
      27: ["6e80ad803118de2c0f8c17446722a7bf"],
      28: ["6e80ad803118de2c0f8c17446722a7bf"],
      29: ["6c5900dfec409a648a1d0bccca4896be"],
      30: ["6c5900dfec409a648a1d0bccca4896be"],
      34: ["947cd975e79ac925b656f830eca717ae"],
      10: ["eb540d0c6b1964624955176afa72c0bf"],
      36: ["eb540d0c6b1964624955176afa72c0bf"],
      37: ["eb540d0c6b1964624955176afa72c0bf"],
      50: ["3a553367f25dab61a085ec95b72876ac"],
      47: ["3a553367f25dab61a085ec95b72876ac"],
      48: ["3a553367f25dab61a085ec95b72876ac"],
      49: ["3a553367f25dab61a085ec95b72876ac"],
      56: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      57: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      58: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      59: ["6e80ad803118de2c0f8c17446722a7bf"],
      60: ["6e80ad803118de2c0f8c17446722a7bf"],
      61: ["6c5900dfec409a648a1d0bccca4896be"],
      62: ["6c5900dfec409a648a1d0bccca4896be"],
      53203: ["eb540d0c6b1964624955176afa72c0bf"],
      15: ["3a553367f25dab61a085ec95b72876ac"],
      16: ["3a553367f25dab61a085ec95b72876ac"],
      17: ["3a553367f25dab61a085ec95b72876ac"],
      18: ["3a553367f25dab61a085ec95b72876ac"],
      19: ["3a553367f25dab61a085ec95b72876ac"],
      20: ["3a553367f25dab61a085ec95b72876ac"],
      21: ["3a553367f25dab61a085ec95b72876ac"],
      22: ["3a553367f25dab61a085ec95b72876ac"],
      23: ["3a553367f25dab61a085ec95b72876ac"],
      42: ["3a553367f25dab61a085ec95b72876ac"],
      43: ["3a553367f25dab61a085ec95b72876ac"],
      44: ["3a553367f25dab61a085ec95b72876ac"],
      45: ["3a553367f25dab61a085ec95b72876ac"],
      46: ["3a553367f25dab61a085ec95b72876ac"],
      24: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      26: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      25: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      66: ["1a55d97e6bb987246e6df70b9d1a1c84"],
      53228: ["7f5829d849ed6f4cea0afb7a297095ef"],
      53229: ["7f5829d849ed6f4cea0afb7a297095ef"],
      53230: ["7f5829d849ed6f4cea0afb7a297095ef"],
      53231: ["7f5829d849ed6f4cea0afb7a297095ef"],
      53240: ["b1e8bd9b4799fc722cd4a323564a5392"],
      53242: ["b1e8bd9b4799fc722cd4a323564a5392"],
      53241: ["b1e8bd9b4799fc722cd4a323564a5392"],
      53243: ["b1e8bd9b4799fc722cd4a323564a5392"],
      53244: ["b1e8bd9b4799fc722cd4a323564a5392"],
      53246: ["8af7d8fcf336227675afd402983ac3eb"],
      53245: ["8af7d8fcf336227675afd402983ac3eb"],
      53267: ["511253181936e3bdf008ba70f1a2a41c"],
      53317: ["ad01d42b5e8452fd0d455f803185aeb5"],
    };
    if (productId in videos) {
      return videos[productId][0];
    }
    return null;
  }

  return (
    <>
      {product.translation.tutorial.length > 0 && (
        <section id="how-to-use" className={isMakeup ? "makeup" : undefined}>
          <div className="container-small">
            <h4
              style={{
                fontFamily: "Brown-Light",
                textTransform: "uppercase",
                color: "rgb(255, 255, 255)",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "30px",
                marginTop: "0px",
              }}
            >
              {t("tutorial-title")}
            </h4>
            {product.translation.tutorial.map((step, i) => {
              return (
                <div className="step" key={"step-" + i}>
                  {step.icon && (
                    <img
                      src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${step.icon}&width=50&height=50`}
                      alt={`Tutorial step ${i}`}
                      height="50"
                      width="50"
                    />
                  )}
                  <span
                    style={{ display: "block", margin: "10px 0px" }}
                    dangerouslySetInnerHTML={{ __html: step.text }}
                  />
                </div>
              );
            })}
            {getTutorialVideo(product.id) && (
              <VideoPlayer
                videoId={getTutorialVideo(product.id)}
                thumbnail={`https://cloudflarestream.com/${getTutorialVideo(
                  product.id
                )}/thumbnails/thumbnail.jpg`}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default ProductTutorial;
