import axios from "axios";
import constants from "../constants";
import { getJWT, validateEmail } from "../helpers";
import { getHeadersWithoutAuth } from "./helpers";
import jwt_decode from "jwt-decode";
import { getPreCheckoutsProducts, getProductById } from "./products";

export const getCartUpsell = async (cart) => {
  if (!cart) {
    return null;
  }

  // ******** Cart based upsell ********
  const response = axios.post(
    `${constants.CART_API_BASE_URL}upsell`,
    cart,
    getHeadersWithoutAuth()
  );
  return response;
  // **********************************

  // ******** Hardcoded upsell ********
  //const HARDCODED_UPSELL_PRODUCT_ID = 53187;
  //let lang = cart.language;
  //let country = cart.country;
  //return getProductById(HARDCODED_UPSELL_PRODUCT_ID, lang, country, "");
  // **********************************
};

export const getCartPreCheckoutsProducts = async (cart) => {
  if (!cart) {
    return null;
  }
  try {
    const response = await axios.post(
      `${constants.CART_API_BASE_URL}pre-checkouts`,
      cart,
      getHeadersWithoutAuth()
    );
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

/**
 * Returns the number of products.
 * @param {object} cart
 */
export function getNumberOfProductsFromOrderlines(cart) {
  let number = cart.orderlines.reduce((total, currentValue) => {
    return total + currentValue.number;
  }, 0);
  return number;
}

export function getUserData(cart) {
  let userData;
  try {
    if (getJWT()) {
      let token = jwt_decode(getJWT());
      userData = {
        id: token.id,
        email: token.email,
        first_name: token.first_name,
        last_name: token.last_name,
      };
    } else {
      userData = {
        id: null,
        email: cart.address.email,
        first_name: cart.address.first_name,
        last_name: cart.address.last_name,
        password: cart.password,
      };
    }
  } catch (err) {
    userData = null;
  }
  return userData;
}

/**
 * Save cart information to the Cloudflare checkouts KV
 *
 * @param {Object} cart
 * @param {Object} paymentMeta
 */
export async function saveCart(cart, paymentMeta) {
  const orderPayload = {
    ...cart,
    userData: cart.userData,
    cart_meta: {
      ...paymentMeta,
    },
    meta: {
      ...paymentMeta,
    },
  };

  if (!orderPayload.hasOwnProperty("userData")) {
    orderPayload["userData"] = getUserData(cart);
  }

  const checkouts = [
    {
      id: cart.cart_id,
      checkout: orderPayload,
    },
  ];

  // We save stripe with both the cart and payment id as keys.
  if ("stripe" in paymentMeta) {
    checkouts.push({
      id: paymentMeta.stripe.id,
      checkout: orderPayload,
    });

    checkouts.forEach((c) => {
      c.checkout.payment_token = paymentMeta.stripe.id;
    });
  }
  await Promise.all(checkouts.map((obj) => createCheckout(obj)));
}

/**
 * Save cart information to the checkout DynamoDb
 *
 * @param {Object} cart
 */
export async function saveCartDynamoDb(cart) {
  return axios.post(
    constants.AWS_CART_CHECKOUTS,
    cart,
    getHeadersWithoutAuth()
  );
}

function createCheckout(checkout_obj) {
  if (
    !checkout_obj.checkout.address ||
    !checkout_obj.checkout.orderlines ||
    !checkout_obj.checkout.orderlines.length
  ) {
    throw new Error("Invalid checkout");
  }

  return axios.post(
    constants.WORKER_CHECKOUTS,
    checkout_obj,
    getHeadersWithoutAuth()
  );
}

export async function getPromo(promo) {
  let promoData = null;
  if (promo) {
    try {
      let promoResponse = await axios.get(
        `https://proxy.cocunat.com/promotions-api/${promo}`
      );
      if (promoResponse.status === 200) {
        promoData = promoResponse.data;
      }
    } catch (err) {
      console.log("updatePromo error:", err);
    }
  }
  return promoData;
}

export async function backendCheckCart(cart) {
  return axios.post(constants.CART_API_BASE_URL + "fix/", cart);
}

export async function getTrustpilotUniqueLink(payload) {
  return axios.post(constants.API_BASE_URL + "trustpilot/", payload);
}

export function isClubinCart(cart) {
  for (let i = 0; i < cart.orderlines.length; i++) {
    if (
      cart.orderlines[i].subscription_type &&
      cart.orderlines[i].subscription_type.includes("GOURMET")
    ) {
      return true;
    }
  }
  return false;
}

export function checkoutModalSuccess(data) {
  return axios.post(
    "https://cocunatapi.net/products-new/upsell-suggestions/",
    data
  );
}
