import React, { useContext, useState } from "react";
import Modal from "../Modal";
import { t } from "../../helpers";
import Button from "../Button";
import { CartContext } from "../../context/cart";
import { useParams, Redirect } from "react-router-dom";
import ProductGourmetSelector from "./ProductGourmetSelector";

const PresaleModal = React.memo(
  ({ isOpenPresaleModal, setIsOpenPresaleModal, product }) => {
    const { lang, country } = useParams();
    const { addProduct } = useContext(CartContext);
    let [redirectTo, setRedirectTo] = useState(null);

    const addToCart = () => {
      addProduct(product);
      setRedirectTo(`/${lang}-${country}/cart`);
    };

    if (redirectTo) {
      return <Redirect push to={redirectTo} />;
    }

    return (
      <>
        {product && (
          <Modal isOpen={isOpenPresaleModal} setIsOpen={setIsOpenPresaleModal}>
            {product && (
              <div
                className="modal-presale-content"
                style={{ textAlign: "center" }}
              >
                <p style={{ textTransform: "uppercase" }}>
                  {product.translation.name}
                </p>
                <img
                  src={`https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&image=https://global.cocunat.com/photos/${product.image}`}
                  alt={product.translation.name}
                  style={{ width: "auto", height: "160px" }}
                />
                <p>
                  {t("product_presale_message", {
                    name: product.translation.name,
                    when: product.restock_estimation_date,
                  })}
                </p>

                <Button
                  size="big"
                  type="primary"
                  width="wide"
                  onClick={addToCart}
                >
                  <span>{t("product_presale")}</span>
                </Button>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
);

export default PresaleModal;
