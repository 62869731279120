import React from "react";
import { t } from "../../helpers";

export default function HomeHeroDefault({ lang }) {
  return (
    <>
      <section id="hero-home-desktop">
        <div
          data-slide="desktop-1"
          style={{
            height: 800,
            position: "relative",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "grey",
            backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=1920&height=1080&image=https://global.cocunat.com/photos/${t(
              "home-hero-desktop-img-1",
              lang
            )}")`,
          }}
        >
          <div
            style={{
              bottom: "50px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Brown-Regular",
                  fontSize: "32px",
                  lineHeight: "37px",
                  marginBottom: "35px",
                }}
              >
                {t("home-hero-text-1", lang)}
              </p>
            </div>
            <div>
              <a
                style={{
                  backgroundColor: "black",
                  color: "rgb(255, 255, 255)",
                  padding: "15px 60px",
                  marginTop: "20px",
                  fontFamily: "Brown-Bold",
                  fontSize: "18px",
                }}
                href={t("home-hero-link-1", lang)}
              >
                {t("home-hero-btn-text-1", lang)}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="hero-home-mobile">
        <div
          style={{
            height: "520px",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "grey",
            backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=600&height=1067&image=https://global.cocunat.com/photos/${t(
              "home-hero-mobile-img-1",
              lang
            )}")`,
          }}
        >
          <div
            style={{
              bottom: "50px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Brown-Regular",
                  fontSize: "20px",
                  lineHeight: "25px",
                  marginTop: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  maxWidth: "300px",
                }}
              >
                {t("home-hero-text-1", lang)}
              </p>
            </div>
            <div>
              <a
                style={{
                  backgroundColor: "black",
                  color: "rgb(255, 255, 255)",
                  padding: "15px 60px",
                  marginTop: "20px",
                  fontFamily: "Brown-Bold",
                  fontSize: "1em",
                }}
                href={t("home-hero-link-1", lang)}
              >
                {t("home-hero-btn-text-1", lang)}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
