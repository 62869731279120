import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../context/cart";

const HomeHeroPromo = React.memo((promoContent) => {
  function clickContent() {
    window.location.href = promoContent.link;
  }
  return (
    <>
      <section id="hero-home-desktop">
        <div
          className="container"
          style={{ maxWidth: 1000, cursor: "pointer" }}
        >
          <div
            data-slide="desktop-1"
            style={{
              height: 570,
              position: "relative",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=1920&height=1080&image=https://global.cocunat.com/photos/${promoContent.homeHero.desktop.img}")`,
            }}
            onClick={clickContent}
          ></div>
        </div>
      </section>
      <section id="hero-home-mobile">
        <div
          style={{
            height: "520px",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=600&height=1067&image=https://global.cocunat.com/photos/${promoContent.homeHero.mobile.img}")`,
          }}
          onClick={clickContent}
        ></div>
      </section>
    </>
  );
});

export default HomeHeroPromo;
