import { useContext, useState } from "react";
import RatingStars from "../RatingStars";
import Button from "../Button";
import Price from "../Price";
import { CartContext } from "../../context/cart";
import { Link, useParams, Redirect } from "react-router-dom";
import { t, buildPhotoUrl } from "../../helpers";
import PromoSticker from "../PromoSticker";
import PresaleModal from "./PresaleModal";
import CONSTANTS from "../../constants";

function ProductLoop({
  product,
  featured = false,
  customHandler = null,
  customText = null,
  customWrapper = false,
  overrideClass = null,
  actionFrom = "ProductLoop",
  showButton = true,
}) {
  const { isGourmet, addProduct } = useContext(CartContext);
  let { lang, country } = useParams();
  let [redirectTo, setRedirectTo] = useState(null);
  const [isOpenPresaleModal, setIsOpenPresaleModal] = useState(false);

  let url = `/${lang}-${country}/product/${product.translation.slug}`;
  let action = "add-to-cart";
  let buttonText = customText || t("add-to-cart");

  // Hardcoded to avoid showing gourmet stuff
  product.prices.show_gourmet = false;

  const hasSimpleSubscription = (product) => {
    // return (
    //   product.has_subscription &&
    //   product.prices &&
    //   product.prices.regular_subscription_price
    // );
    return false;
  };

  if (
    product.is_productcontainer ||
    (product.prices && product.prices.show_gourmet && !isGourmet)
  ) {
    action = "view";
    buttonText = t("view_product");
  }

  if (hasSimpleSubscription(product)) {
    action = "view";
    buttonText = customText || t("view_product");
  }

  if (product.is_productcontainer || product.id == 53317) {
    url = `/${lang}-${country}/product/makeup/${product.translation.slug}`;
  }

  if (product.stockout) {
    action = "stockout";
    buttonText = t("view_product");
  }

  if (product.presale) {
    action = "presale";
    buttonText = t("product_presale");
  }

  let classes = overrideClass
    ? overrideClass
    : "product-item col-md-3 col-sm-4 col-xs-6";
  if (featured) {
    classes = "product-item featured col-md-4 col-sm-4 col-xs-6";
  }

  const addToCartHandler = (product) => {
    product.actionFrom = actionFrom;
    addProduct(product);

    setRedirectTo(`/${lang}-${country}/cart`);
  };

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  function getProductCartElement() {
    return (
      <>
        <figure style={{ position: "relative" }}>
          {featured ? (
            <img
              src={buildPhotoUrl("photos/" + product.pretty_image, 355, 285)}
              alt={product.translation.name}
              height="355"
              width="285"
              loading="lazy"
            />
          ) : (
            <img
              src={buildPhotoUrl(
                product.pretty_image
                  ? "photos/" + product.pretty_image
                  : "photos/" + product.image,
                355,
                285
              )}
              alt={product.translation.name}
              height="355"
              width="285"
              loading="lazy"
            />
          )}
          {CONSTANTS.LAST_UNITS_PRODUCTS.includes(product.id) ? (
            <div className={`product-tag`}>{t("tags.last-units")}</div>
          ) : (
            CONSTANTS.HIGH_DEMAND_PRODUCTS.includes(product.id) && (
              <div className={`product-tag`}>{t("tags.high-demand")}</div>
            )
          )}
          <PromoSticker
            discount_applied={product.prices.discount_applied}
            clubDiscount={product.prices.gourmet_product_discount}
            showGourmet={
              product.prices && product.prices.show_gourmet && !isGourmet
            }
            lang={lang}
            rendered_from={"product-loop"}
            className={"promo-tag"}
          />
        </figure>
        <div className="product-title">
          <p style={{ fontSize: 12 }}>
            <RatingStars rating={product.rating} size={12} />
            <span style={{ marginLeft: 5 }}>
              {product.rating} (
              {product.comments_summary ? product.comments_summary.num : 0})
            </span>
          </p>

          <p
            style={{
              fontSize: "13px",
              maxHeight: "100px",
              overflow: "hidden",
            }}
          >
            <strong
              style={{
                fontSize: "16px",
                display: "block",
                textTransform: "uppercase",
              }}
            >
              {product.translation.name}
            </strong>
            {product.translation.subtitle}
          </p>

          <p
            style={{
              fontSize: "14px",
              margin: "10px 0px",
              height: 15,
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
              /*color: "#767676",*/
              color: "#000",
            }}
          >
            <strong>{t("actualPrice").toUpperCase()}: </strong>
            <strong>
              <Price
                prices={product.prices}
                className="product-loop"
                showGourmetDiscount={false}
              />
            </strong>
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes}>
        <PresaleModal
          isOpenPresaleModal={isOpenPresaleModal}
          setIsOpenPresaleModal={setIsOpenPresaleModal}
          product={product}
        />
        {customWrapper ? (
          <div>{getProductCartElement()}</div>
        ) : (
          <Link to={`${url}`}>{getProductCartElement()}</Link>
        )}
        {showButton && (
          <Button
            style={{ backgroundColor: "black" }}
            onClick={() => {
              if (customHandler) {
                customHandler(product);
              } else if (action === "add-to-cart") {
                addToCartHandler(product);
              } else if (action === "presale") {
                setIsOpenPresaleModal(true);
              } else if (action === "stockout") {
              } else {
                setRedirectTo(url);
              }
            }}
          >
            <span>{buttonText}</span>
          </Button>
        )}
      </div>
    </>
  );
}

export default ProductLoop;
