import React, { useEffect, useState } from "react";

function Checkbox({
  onChange,
  label,
  error = false,
  onPressLabel,
  defaultValue,
  wrapperStyle,
  checkboxStyle,
  actionLabel,
}) {
  let [selected, setSelected] = useState(false);

  function _onChange(option) {
    setSelected(option);
    onChange(option);
  }

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className={
        error ? "cocunat-CheckboxGroup error" : "cocunat-CheckboxGroup"
      }
      style={wrapperStyle}
    >
      <div className="checkbox-button" onClick={() => _onChange(!selected)}>
        <div className="checkbox-button__inner" style={checkboxStyle}>
          {selected ? (
            <div className="CheckboxGroup_circle-o"></div>
          ) : (
            <div className="CheckboxGroup_circle"></div>
          )}
          {onPressLabel ? (
            actionLabel ? (
              <>
                <span dangerouslySetInnerHTML={{ __html: label }} />
                <button onClick={onPressLabel}>
                  <span dangerouslySetInnerHTML={{ __html: actionLabel }} />
                </button>
              </>
            ) : (
              <button onClick={onPressLabel}>
                <span dangerouslySetInnerHTML={{ __html: label }} />
              </button>
            )
          ) : (
            <span dangerouslySetInnerHTML={{ __html: label }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkbox;
