import { useContext, useState } from "react";
import { CartContext } from "../context/cart";
import { t } from "../helpers";
import PriceWithSymbol from "./PriceWithSymbol";

function CartTotals(props) {
  const { cart } = useContext(CartContext);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="totals" style={props.style}>
      {cart && cart.totals && (
        <>
          <div
            className="totals-header"
            onClick={() => setCollapsed(!collapsed)}
          >
            <p>
              <strong>{t("total").toUpperCase()}</strong>
            </p>
            <div>
              <strong>
                <PriceWithSymbol
                  amount={cart.totals.total}
                  format={
                    cart.totals.currency ? cart.totals.currency.format : null
                  }
                  symbol={
                    cart.totals.currency ? cart.totals.currency.symbol : null
                  }
                />
              </strong>
              {collapsed ? (
                <img
                  src={"https://global.cocunat.com/angle-up-v2.png"}
                  width="20"
                  height="20"
                  alt="Expand"
                  style={{
                    marginTop: "7px",
                    marginRight: "-5px",
                    marginLeft: "5px",
                  }}
                />
              ) : (
                <img
                  src={"https://global.cocunat.com/angle-down-v2.png"}
                  width="20"
                  height="20"
                  alt="Expand"
                />
              )}
            </div>
          </div>
          {collapsed && (
            <div className="totals-content">
              <p>
                <strong>{t("subtotal").toUpperCase()}</strong>
                <PriceWithSymbol
                  amount={cart.totals.subtotal}
                  format={
                    cart.totals.currency ? cart.totals.currency.format : null
                  }
                  symbol={
                    cart.totals.currency ? cart.totals.currency.symbol : null
                  }
                />
              </p>
              {cart.totals.discount > 0 && (
                <p>
                  <strong>{t("discount").toUpperCase()}</strong>
                  <PriceWithSymbol
                    amount={-cart.totals.discount}
                    format={
                      cart.totals.currency ? cart.totals.currency.format : null
                    }
                    symbol={
                      cart.totals.currency ? cart.totals.currency.symbol : null
                    }
                  />
                </p>
              )}
              {cart.totals.balance > 0 && (
                <p>
                  <strong>{t("balance").toUpperCase()}</strong>
                  <PriceWithSymbol
                    amount={-cart.totals.balance}
                    format={
                      cart.totals.currency ? cart.totals.currency.format : null
                    }
                    symbol={
                      cart.totals.currency ? cart.totals.currency.symbol : null
                    }
                  />
                </p>
              )}
              {cart.totals.taxes > 0 && (
                <p>
                  <strong>TAXES</strong>
                  <PriceWithSymbol
                    amount={cart.totals.taxes}
                    format={
                      cart.totals.currency ? cart.totals.currency.format : null
                    }
                    symbol={
                      cart.totals.currency ? cart.totals.currency.symbol : null
                    }
                  />
                </p>
              )}
              <p>
                <strong>{t("subtotal_with_discount").toUpperCase()}</strong>
                <PriceWithSymbol
                  amount={cart.totals.subtotal_with_discounts}
                  format={
                    cart.totals.currency ? cart.totals.currency.format : null
                  }
                  symbol={
                    cart.totals.currency ? cart.totals.currency.symbol : null
                  }
                />
              </p>
              <p>
                <strong>{t("shipping_cost").toUpperCase()}</strong>
                <PriceWithSymbol
                  amount={cart.totals.shipping}
                  format={
                    cart.totals.currency ? cart.totals.currency.format : null
                  }
                  symbol={
                    cart.totals.currency ? cart.totals.currency.symbol : null
                  }
                />
              </p>
              <p>
                <strong>{t("total").toUpperCase()}</strong>
                <PriceWithSymbol
                  amount={cart.totals.total}
                  format={
                    cart.totals.currency ? cart.totals.currency.format : null
                  }
                  symbol={
                    cart.totals.currency ? cart.totals.currency.symbol : null
                  }
                />
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CartTotals;
