import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { getContent } from "../logic/content";
import { t } from "../helpers";

export default function Terms({ isOpen, setIsOpen }) {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const contentData = await getContent(t("terminos"));
    console.log(contentData);
    if (contentData.data.results && contentData.data.results.length > 0) {
      setContent(contentData.data.results[0]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {isLoading ? (
        <img
          src="https://global.cocunat.com/icons/loading.gif"
          height="25"
          width="25"
          alt="Loading"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <>
          {content && content.main_content && (
            <>
              <h1>{content.name}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.main_content,
                }}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
}
