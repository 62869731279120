import { t } from "../../helpers";
import React from "react";

const ProductIngredients = React.memo(({ product }) => {
  return (
    <section id="ingredients">
      <h4 className="heart-title">{t("ingredients-title")}</h4>
      {Object.keys(product.products_pack).length === 0 &&
        product.translation.ingredients.length > 1 &&
        product.translation.ingredients.length % 2 === 0 && (
          <div className="infographic-ingredientes">
            {product.translation.ingredients.map((ingredient, i) => {
              return (
                <div
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-6 incii-img"
                  key={"ingredient-" + i}
                >
                  <img
                    src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${ingredient.icon}`}
                    width="310"
                    height="596"
                    alt="Results"
                    sizes="(min-width: 650px) 100%, 100%"
                  />
                </div>
              );
            })}
          </div>
        )}
      <div className="container-small">
        <p
          className="ingredient-small"
          dangerouslySetInnerHTML={{ __html: product.attributes.ingredients }}
        />
      </div>
    </section>
  );
});

export default ProductIngredients;
