import { t } from "../../helpers";
import Price from "../Price";
import RatingStars from "../RatingStars";
import React from "react";

const ProductMainTitle = React.memo(({ product }) => {
  return (
    <>
      <div id="title">
        <h1>{product.translation.name}</h1>
        <h2>{product.translation.tagline}</h2>
        {product?.attributes?.metric_size > 0 && (
          <p style={{ margin: "5px 0px", lineHeight: "18px" }}>
            {product.attributes.metric_size}{" "}
            <span style={{ fontSize: "80%" }}>ML</span>{" "}
          </p>
        )}
      </div>
      <p id="rating" style={{ textAlign: "left", fontSize: 15, margin: 0 }}>
        <small>
          <RatingStars rating={product.rating} size={15} />
          <span style={{ marginLeft: "5px", color: "black" }}>
            {product.comments_summary ? product.comments_summary.num : 0}{" "}
            <a href="#comments" style={{ color: "black" }}>
              {" "}
              {t("comments")}
            </a>
          </span>
        </small>
      </p>
    </>
  );
});

export default ProductMainTitle;
