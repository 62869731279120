import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../context/cart";
import { overrideBodyClass } from "../helpers";
import { getPromoContent } from "../logic/promotions";

function PromoTopBanner() {
  let [promoContent, setPromoContent] = useState(null);
  const { region, promo } = useContext(CartContext);

  useEffect(() => {
    if (region) {
      let promoCode = promo && promo.code ? promo.code : null;
      let content = getPromoContent(region, promoCode);
      setPromoContent(content);
    }
    if (promoContent && promoContent.topBanner) {
      overrideBodyClass(document.body.classList, "promo-on");
    } else {
      // overrideBodyClass(document.body.classList, "promo-off");
      overrideBodyClass(document.body.classList, "promo-on");
    }
  }, [region, promo]);

  return (
    <>
      {promoContent && promoContent.topBanner && (
        <div
          id={"top-promo-banner"}
          style={{
            backgroundColor: promoContent.topBanner.bG.replace(";", ""),
          }}
        >
          <p
            style={{
              color: promoContent.topBanner.color.replace(";", ""),
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = promoContent.link;
            }}
          >
            {promoContent.topBanner.text}
          </p>
        </div>
      )}
    </>
  );
}

export default PromoTopBanner;
